.container {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    background: rgb(39, 43, 51);
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    min-width: 100wh;
    color: rgb(232, 230, 227);
    flex-direction: column;
}

.card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    color: rgb(232, 230, 227);
    background-color: rgb(31, 34, 41);
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.status {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    padding: 0.5rem;
}
.status-dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.grey {
    background-color: grey;
}
a{
    text-decoration: none;
    color: rgb(232, 230, 227);
}
a:hover{
        color: rgb(255, 152, 0);
}